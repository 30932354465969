<template>
	<div class="container" v-title :data-title="newsDetails.info.title">
    <div class="breadcrumbs-box">
      <div class="breadcrumbs">
        <i class="el-icon-s-home home-icon"></i>
        <div class="item">
          <div>
            <router-link to="/">首页</router-link>
          </div>
        </div>
        <i class="el-icon-arrow-right right-icon"></i>
        <div class="item">
          <div v-if="newsDetails&&newsDetails.info">
            <router-link target="_blank" :to="'/newslist?menuId='+newsDetails.info.menuId+'&menuName='+newsDetails.info.menuName">{{ newsDetails.info.menuName }}</router-link>
          </div>
        </div>
        <i class="el-icon-arrow-right right-icon"></i>
        <div class="item" v-if="newsDetails&&newsDetails.info">
          <div>{{ newsDetails.info.title }}</div>
        </div>
      </div>
    </div>
    <div class="container-box">
      <div class="details-box">
        <div class="title" v-if="newsDetails&&newsDetails.info">{{ newsDetails.info.title }}</div>
        <!-- <div class="title-summary" v-if="newsDetails&&newsDetails.info&&newsDetails.info.summary">{{ newsDetails.info.summary }}</div> -->
        <div class="sub-title">
          <div v-if="newsDetails&&newsDetails.info">作者：{{ newsDetails.info.author }}</div>
          <!-- <div v-if="newsDetails&&newsDetails.info">来源：{{ newsDetails.info.receiverName }}</div> -->
          <div v-if="newsDetails&&newsDetails.info">日期：{{ newsDetails.info.createTime }}</div>
        </div>
        <div class="details" v-if="newsDetails&&newsDetails.info">
          <div class="details-content" v-html="newsDetails.info.content"></div>

          <div class="details-content-files" v-if="newsDetails&&newsDetails.fileList&&newsDetails.fileList.length>0">
              <div class="download-file" v-for="item in newsDetails.fileList" :key="item.id">
                <a :href="item.content" :download="item.title" target="_blank">{{ item.title }}</a>
              </div>
          </div>
        </div>
      </div>
      <div class="other-news-list">
        <div class="title">
          推荐新闻
          <div>
            <i class="el-icon-arrow-right right-icon"></i>
            <i class="el-icon-arrow-right right-icon"></i>
          </div>
        </div>
        <ul class="news-box">
          <li class="news-item" v-for="item in newsImportantList" :key="item.newsNumber">
            <router-link target="_blank" :to="'/newsdetails?newsNumber='+item.newsNumber" :title="item.title">
              {{ item.title }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsAllInfo,getNewsImportantList } from '../../network/news'

export default {
  name: "NewsDetails",
  data () {
    return {
      newsDetails: {},
      newsImportantList: []
    };
  },
  props: {
    
  },
  components: {
    
  },
  mounted() {
    this.getNewsImportantList()

    const params = this.$route.query;
    if(params && params.newsNumber) {
      this.newsNumber = params.newsNumber
      this.getNewsAllInfo(this.newsNumber)
    }
    // if(params && params.menuName) {
    //   this.menuName = params.menuName
    // }
  },
  methods: {
    
    // 网络请求
    getNewsAllInfo(newsNumber) {
      getNewsAllInfo(newsNumber).then(res=>{
        if(res.status===200&&res.data) {
          this.newsDetails = res.data
          document.title = this.newsDetails.info.title
          console.log('新闻详情',this.newsDetails)
        }
      })
    },
    getNewsImportantList() {
      getNewsImportantList().then(res=>{
        if(res.status===200&&res.data) {
          this.newsImportantList = res.data
          console.log('推荐新闻列表',this.newsList)
        }
      })
    },
  }
}
</script>

<style scoped>
  .details-content>>>img {
    max-width: 100%;
    margin: 10px 0;
  }
  .details-content>>>a {
    color: #0B5EA4 !important;
    /* text-decoration: underline !important; */
    cursor: pointer !important;
  }
  .details-content>>>a span {
    color: #0B5EA4 !important;
  }
  
  .details-content-files>>>a {
    color: #0B5EA4 !important;
    /* text-decoration: underline !important; */
    cursor: pointer !important;
  }
</style>
<style lang='less' scoped>
.container {
  width: 100%;
  .breadcrumbs-box {
    width: 100%;
    height: 40px;
    background-color: #eee;
    .breadcrumbs {
      width: 1200px;
      height: 40px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .home-icon {
        color: var(--theme-text-color);
        font-size: 14px;
      }
      .right {
        color: #535353;
        font-size: 14px;
      }
      .item {
        margin-left: 10px;
        font-size: 14px;
        color: #535353;
        cursor: pointer;
      }
    }
  }
  .container-box {
    width: 1200px;
    margin: 20px auto 35px;
    display: flex;
    justify-content: space-between;
    .details-box {
      width: 870px;
      border: 1px solid #e5e5e5;
      padding: 35px;
      .title {
        font-size: 28px;
        color: #313131;
        font-weight: bold;
        text-align: center;
        line-height: 46px;
        margin-bottom: 10px;
      }
      .title-summary {
        width: 95%;
        margin: 0 auto;
        font-size: 16px;
        color: #313131;
        text-align: center;
        line-height: 1.5;
        margin-bottom: 10px;
      }
      .sub-title {
        display: flex;
        justify-content: space-around;
        border-bottom: 1px solid #dcdcdc;
        font-size: 14px;
        color: #666;
        line-height: 32px;
      }
      .details {
        padding-top: 25px;
        .details-content {
          width: 100% ;
          line-height: 2;
        }
        .details-content-files {
          width: 100% ;
          line-height: 2;
          margin-top: 20px;
        }
      }
    }
    .other-news-list {
      width: 310px;
      .title {
        border-top: 10px solid var(--theme-color);
        background-color: #eee;
        height: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 34px;
        font-size: 20px;
        font-weight: bold;
        color: var(--theme-text-color);
        .right-icon {
          color: var(--theme-text-color);
        }
      }
      .news-box {
        border: 1px solid #e5e5e5;
        padding: 10px 20px;
        .news-item {
          padding: 0 20px;
          height: 35px;
          line-height: 35px;
          list-style-type: none;
          padding-left: 20px;
          color: #1b1b1b;
          font-size: 16px;
          cursor: pointer;
          position: relative;
          
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &::before {
            content: "";
            display: inline-block;
            width: 5px;
            height: 5px;
            background-color: var(--theme-color);
            rotate: 45deg;
            margin-right: 10px;
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
          }
          
          &:hover {
            a {
              color: var(--theme-color);
            }
          }
        }
      }
    }
  }
}
</style>
